<template>
    <div class="jv-item" :class="{ 'jv-root': isRoot }">
        <div v-if="isRoot" title="копировать в буфер" class="jv-copy" @click="copy" />
        <div v-for="(el, num) in localTree" :key="num" class="jv-item--children">
            <div v-if="el.children.length">
                <div class="jv-item-header">
                    <div class="jv-item-header-left">
                        <span class="jv-item-arr" :class="{ open: el.localOpen }" @click="doOpen($event, el)" />
                        <span class="jv-key-text" @click="doOpen($event, el)">{{ el.key }}</span>
                        <span class="jv-key-d-dots" @click="doOpen($event, el)">:&nbsp;</span>
                        <span class="jv-b" @click="doOpen($event, el)">{{ bracket(el, 'L') }}</span><span v-if="!el.localOpen" class="jv-b" @click="doOpen($event, el)">...</span><span class="jv-b" @click="doOpen($event, el)">{{ bracket(el, 'R') }}</span>
                    </div>
                    <div
                        :class="el.localOpen ? 'od--close-all' : 'od--open-all'"
                        :title="`${el.localOpen ? 'свернуть' : 'развернуть'} все элементы внутри`"
                        @click="childrenToggle(el)"
                    />
                </div>
                <JsonViewer v-if="el.localOpen" :val="el.children" :cut-string="cutString" />
            </div>
            <div v-else class="od--string-block">
                <span class="jv-item-header">
                    <span class="jv-key-text">{{ el.key }}</span><span>:&nbsp;</span></span><span class="jv-item-val" :class="`jv-item-type-${el.type}`">{{ el.val }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { BaseProps } from '~/components/UiKit/base/types'

/**
 * Types
 */
interface Item {
    val: string
    key: string
    type: string
    level: number
    localOpen: boolean
    children: Item[]
}

interface Props extends BaseProps {
    val: Record<string, any> | any[] | any
    open?: boolean
    cutString?: boolean
}

/**
 * Props
 */
const props = withDefaults(defineProps<Props>(), {
    open: false,
    cutString: true,
})

const instance = getCurrentInstance()

/**
 * Data
 */
const tree = ref<Item>()

/**
 * Watch
 */
watch(() => props.val, () => parse(), { deep: true })

/**
 * Computed
 */
const isRoot = computed((): boolean => {
    if (!instance) {
        return true
    }
    return !instance?.parent?.type.__name || !instance.parent.type.__name.toLowerCase().includes('jsonviewer')
})

const localTree = computed(() => {
    return isRoot.value ? tree.value?.children || [] : props.val as Item[]
})

/**
 * Methods
 */
function parse() {
    if (!isRoot.value) {
        return
    }
    tree.value = initData(props.val, 'root', props.open, null)
}

function iterator(obj: Record<string, any> | any[], callback: (...args: any[]) => void) {
    const isArray = Array.isArray(obj)

    ;(isArray ? obj : Object.keys(obj)).forEach((i, index) => {
        const item = isArray ? i : obj[i]
        const key = isArray ? index : i
        callback(item, key)
    })
}

function bracket(item: Item, dir: 'L' | 'R') {
    if (!item.children.length) {
        return ''
    }
    if (item.type === 'object') {
        return dir === 'L' ? '{' : '}'
    } else {
        return dir === 'L' ? '[' : ']'
    }
}

function getVal(item: any) {
    const type = getType(item)
    let val = ''
    switch (type) {
        case 'object':
            val = hiddenObjProp(item)
            break
        case 'array':
            val = `Array (${item.length})`
            break
        case 'number':
        case 'empty':
        case 'string':
        case 'boolean':
            val = `${item}`
            break
    }
    if (type === 'string' && val.length > 150 && props.cutString) {
        val = `${val.substring(0, 143)}...${val.slice(-4)}`
    }
    return val
}

function hiddenObjProp(obj: any) {
    let keys = Object.keys(obj)
    if (!keys.length) {
        return ''
    }
    if (keys.length > 1) {
        return '...'
    }
    let firstVal = Object.values(obj)[0]
    if (getType(firstVal) === 'object') {
        return hiddenObjProp(firstVal)
    } else {
        return `${keys[0]}: ${firstVal}`
    }
}

function doOpen(e: MouseEvent, el: Item) {
    if (!el.children.length) {
        return
    }
    if (process.client && window.getSelection()?.type === 'Range') {
        return
    }
    el.localOpen = !el.localOpen
    prev(e)
}

function getType(val: any) {
    let type
    if (Array.isArray(val)) {
        type = 'array'
    } else if (val && val.toString() === '[object Object]') {
        type = 'object'
    } else if (typeof val === 'number') {
        type = 'number'
    } else if (val !== '' && (!val || val === true)) {
        type = val == null ? 'empty' : 'boolean'
    } else {
        type = 'string'
    }
    return type
}

function prev(e: MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
}

function childrenToggle(el: Item) {
    el.localOpen = !el.localOpen
    const children = getChildren(el)
    children.forEach(ch => (ch.localOpen = el.localOpen))
}

function getChildren(el: Item) {
    let children = el.children
    if (!children.length) {
        return children
    }
    children.forEach(ch => (children = [...children, ...getChildren(ch)]))
    return children
}

function initData(el: Record<string, any> | Item | any[], key: string, open: boolean, parent: Item | null) {
    const type = getType(el)
    const item: Item = {
        val: getVal(el),
        key,
        type,
        level: parent ? parent.level + 1 : 0,
        localOpen: open || false,
        children: [],
    }
    if (!['array', 'object'].includes(type)) {
        return item
    }
    iterator(el, (child, key) => item.children.push(initData(child, key, open, item)))
    return item
}

async function copy() {
    try {
        await navigator.clipboard.writeText(JSON.stringify(props.val))
    } catch (err) {
        console.error('Failed to copy: ', err)
    }
}

onMounted(() => {
    parse()
})
</script>

<style>
.jv-item {
    font-family: monospace, sans-serif;
    font-size: 13px;
    line-height: 15px;
    overflow: hidden;
    margin-left: 4px;
    position: relative;
    border-left: 1px solid #e0e0e0;
    padding-left: 6px;
    padding-top: 4px;

    *::selection {
        background: #baddfa;
        color: unset;
    }
}

.jv-root {
    padding: 0 25px 0 0;
    background: #ffffff;
    border-radius: 1px;
    border: none;
    margin: 0;
    max-width: 100%;
    overflow: auto;
}

.jv-item--children {
    width: 100%;
    margin-bottom: 2px;
}

.od--string-block {
    padding-left: 12px;
}

.jv-key-text {
    overflow: hidden;
    text-overflow: ellipsis;
    color: #881391;
}

.jv-b {
    font-size: 11px;
    line-height: 10px;
    color: #777777;
}

.jv-item-val {
    color: #3b3b3b;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    word-break: break-word;
    /*word-break: break-all;*/

    &.jv-item-type-boolean {
        color: #1223a8;
    }

    &.jv-item-type-empty {
        color: #8b8b8b;
    }

    &.jv-item-type-number {
        color: #3a22d5;
    }

    &.jv-item-type-string {
        color: #c72522;
    }
}

.jv-item-arr {
    position: relative;
    cursor: pointer;
    width: 10px;
    height: 15px;
    line-height: 15px;
    flex-shrink: 0;
    margin-right: 2px;
    text-align: left;
    vertical-align: middle;
    &:before {
        position: absolute;
        top: 5px;
        display: block;
        border: 3px solid transparent;
        border-top: 4px solid #aaaaaa;
        width: 0;
        height: 0;
        content: '';
        transform-origin: 4px 2px;
        transform: rotate(-90deg);
        transition: transform 0.05s linear;
    }

    &.open:before {
        transform: rotate(0deg);
    }
}

.jv-copy {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 16px;
    height: 16px;
    background-color: white;
    cursor: pointer;
    &:after,
    &:before {
        position: absolute;
        display: block;
        border: 1px solid #727272;
        border-radius: 2px;
        width: 8px;
        height: 8px;
        content: '';
        background-color: white;
    }
    &:before {
        right: 2px;
        top: 2px;
    }
    &:after {
        right: 5px;
        top: 5px;
    }
    &:hover {
        &:after,
        &:before {
            border-color: tomato;
        }
    }
}

.jv-item-header {
    display: inline-flex;

    max-width: 100%;
    overflow: hidden;
}
.jv-item-header-left {
    max-width: 100%;
    display: inline-flex;
    cursor: pointer;
    align-items: center;
    overflow: hidden;
    &:hover {
        & > .jv-item-arr:before,
        & > .jv-key-d-dots,
        & > .jv-key-text,
        & > .jv-b {
            color: tomato;
        }
    }
}

.od--open-all,
.od--close-all {
    flex-shrink: 0;
    width: 16px;
    height: 15px;
    margin-left: 8px;
    background-color: white;
    cursor: pointer;
    position: relative;
    transition: transform 0.1s linear;
    &:after,
    &:before {
        position: absolute;
        display: block;
        border: 3px solid transparent;
        border-top: 4px solid #aaaaaa;
        width: 0;
        height: 0;
        content: '';
    }
    &:before {
        left: 4px;
        top: 4px;
    }
    &:after {
        left: 4px;
        top: 8px;
    }
    &:hover {
        &:after,
        &:before {
            border-top-color: tomato;
        }
    }
}

.jv-item {
    .od--close-all {
        transform: rotate(180deg);
    }
}
</style>
