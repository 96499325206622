import type { BootData } from 'back/src/apps/api-ui/routes/001_auth'
import { useAppStore } from '~/stores/app'
import { useUserStore } from '~/stores/user'
import { loadFieldsConfig } from '~/utils/fieldsConfig'
import { systemLanguagesLoad } from '~/utils/languages'

let boot = true
export default defineNuxtRouteMiddleware(async (to, from) => {
    if (!boot) {
        return
    }

    const data = await useFetchCall<BootData, any>('/boot', {})
    useConfigStore().$patch(data.config)
    useUserStore().$patch(data.user)
    useAppStore().$patch({
        appLanguages: data.languages,
        langUI: data.user.settings.langUi,
        langData: data.user.settings.langData,
        backVersion: data.version,
    })

    systemLanguagesLoad(data.languages)
    loadFieldsConfig(data.dfc)
    boot = false

    // load SPA version
    fetch('/version.txt')
        .then(r => r.text())
        .then(frontVersion => {
            useAppStore().$patch({ frontVersion })
        })

    // todo? setup user load every 15m

    const returnToAfterAuth = sessionStorage?.getItem('returnToAfterAuth')
    if (returnToAfterAuth) {
        sessionStorage.removeItem('returnToAfterAuth')
        return navigateTo(returnToAfterAuth)
    }
})
