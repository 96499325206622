<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { h } from 'vue'
import { RouterLink, useLink } from 'vue-router'
import spinnerSvg from '~/assets/svg/spinner.svg?raw'
import Icon from './RIcon.vue'

/** цвет */
export type IBtnColor = 'grey' | 'green' | 'red' | 'blue' | 'orange' | 'white'

/** текст на кнопке */
type Text = string

/** принудительные цвета в иконке через пробел, если отличается от color */
type IconColor = string
/** размер копки default=normal */
type Size = string | 'inline' | 'small' | 'extra-small' | 'normal' | 'big'
/** позиция иконки default=left */
type Direction = 'left' | 'right'
/** positive (прозрачная), solid (залитая), border (прозрачная с бордером) */
export type IBtnMode = 'positive' | 'solid' | 'border' | 'underline'

/** заблокирована надолго */
type Disable = boolean
/** заблокирована на выполнение (spinner) */
type Wait = boolean

export default defineComponent({
    name: 'Btn',
    components: { Icon },
    props: {
        text: { type: String as PropType<Text>, default: '' },
        icon: { type: String as PropType<AppIcon>, default: '' },
        iconColor: { type: String as PropType<IconColor>, default: '' },
        iconRatio: { type: Number },
        size: { type: String as PropType<Size>, default: 'normal' },
        direction: { type: String as PropType<Direction>, default: 'left' },
        mode: {
            type: String as PropType<IBtnMode>,
            default: 'solid',
        },
        color: {
            type: String as PropType<IBtnColor | string>,
            default: 'blue',
        },
        disable: {
            type: Boolean as PropType<Disable>,
            default: false,
        },
        tabindex: { type: [String, Number] },
        wait: {
            type: Boolean as PropType<Wait>,
            default: false,
        },
        to: {
            type: [String, Object],
            default: '',
        },
    },
    emits: ['click'],
    data() {
        return {
            iconLoadError: false,
        }
    },
    computed: {
        ratio() {
            if (this.iconRatio) {
                return this.iconRatio
            }
            switch (this.size) {
                case 'inline':
                case 'small':
                    return 0.8
                case 'extra-small':
                    return 0.6
                case 'big':
                    return 1.2
            }
            return 0
        },
        compClass() {
            const classes: string[] = [`r-btn-${this.mode}`, `r-btn-${this.size}`]

            if (this.icon && !this.iconLoadError && (this.text || this.$slots.default)) {
                switch (this.direction) {
                    case 'left':
                        classes.push('r-btn-l')
                        break
                    case 'right':
                        classes.push('r-btn-r')
                        break
                }
            }

            if (this.to) {
                classes.push('a-btn')
            }

            if (this.wait) {
                classes.push('r-btn-wait')
            }

            if (this.disable) {
                classes.push('disabled')
                return classes
            }

            if (this.mode === 'solid') {
                classes.push(`r-btn-bg-${this.color}`)
                return classes
            }

            classes.push(`r-btn-c-${this.color}`)
            return classes
        },
        href() {
            if (!this.to) {
                return ''
            }
            if (typeof this.to === 'string') {
                return this.to
            }

            // @see https://router.vuejs.org/guide/advanced/composition-api.html#uselink
            // @ts-expect-error RouterLink.props
            const props = { ...RouterLink.props, to: this.to }
            const { href } = useLink(props)
            return href.value
        },
    },
    methods: {
        onClick(event: MouseEvent) {
            if (this.disable || this.wait) {
                return
            }
            this.$emit('click', event)
            if (this.to && !(typeof this.to !== 'string' && this.to.external)) {
                this.$router.push(this.to)
                event.preventDefault()
            }
        },
    },
    render() {
        let htmlProps = {
            class: ['r-btn', ...this.compClass],
            onClick: this.onClick,
            href: this.href || null,
            tabindex: this.disable ? -1 : this.tabindex || 0,
            // download не делать дефолтного значения
        }
        if (typeof this.to !== 'string' && this.to.download) {
            // @ts-expect-error download
            htmlProps.download = this.to.download
        }
        return h(this.to ? 'a' : 'button', htmlProps, [
            this.icon && !this.iconLoadError
                ? h(Icon, {
                    color: this.disable ? '' : this.iconColor,
                    name: this.icon,
                    width: 0,
                    height: 0,
                    ratio: this.ratio,
                    onLoadError: () => (this.iconLoadError = true),
                })
                : '',
            this.text ? h('span', { class: ['r-btn-t'] }, [this.text]) : null,
            this.$slots.default ? h('span', { class: ['r-btn-t'] }, [this.$slots.default()]) : null,
            this.wait ? h('span', { tabindex: -1, class: ['r-btn-wait-block'], innerHTML: spinnerSvg }) : null,
        ])
    },
})
</script>
