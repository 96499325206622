import { useNuxtApp } from '#app'
import { defineStore } from 'pinia'
import type { TStatus } from '~/types'

export interface AppLang {
    id: string
    sid: number
    code: string
    name: string
    nameEn: string
    systemStatus: TStatus
    uiUsage: boolean
    dataUsage: boolean
    localisationRule: string
}

export type AppTitle = (({ name: string, classes?: string }[]) | (string[]) | string)

interface AppStore {
    title: AppTitle
    langUI: string
    langData: string
    appLanguages: AppLang[]
    backVersion: string
    frontVersion: string
}

export const useAppStore = defineStore('app', {
    state: (): AppStore => ({
        title: 'Referus',
        langUI: 'en',
        langData: 'en',
        appLanguages: [],
        backVersion: '??',
        frontVersion: '??',
    }),
    actions: {
        setTitle(title: AppTitle) {
            this.title = title
            document.title = Array.isArray(title) ? title.map(i => typeof i === 'string' ? i : i.name).join(' ') : title
        },
        setLangUI(lang: string) {
            const app = useNuxtApp()
            const i18nextInstance = app.$i18next as any
            document.querySelector('html')?.setAttribute('lang', lang)
            if (i18nextInstance?.language === lang) {
                this.langUI = lang
                return
            }
            i18nextInstance?.changeLanguage(lang).then(() => {
                localStorage.setItem('appLang', lang)
            })
            return useFetchCall('/userLanguageUISet', { lang }).then(() => (this.langUI = lang))
        },
        async reloadTranslations() {
            const app = useNuxtApp()
            const i18nextInstance = app.$i18next as any
            await i18nextInstance.reloadResources([this.langUI])
        },
        setLangData(lang: string) {
            if (this.langData === lang) {
                return
            }
            return useFetchCall('/userLanguageDataSet', { lang }).then(() => (this.langData = lang))
        },
    },
})
