import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import I18NextVue from 'i18next-vue'

export default defineNuxtPlugin(async (nuxtApp: any) => {
    const lng = localStorage.appLang || 'en'
    await i18next
        .use(Backend)
        .init({
            lng,
            debug: false,
            interpolation: {
                escapeValue: false,
                skipOnVariables: false,
            },
            ns: 'front',
            backend: {
                loadPath: '/api/ui/locales/{{lng}}',
            },
        })

    const appVue = nuxtApp.vueApp.use(I18NextVue, { i18next })

    return {
        provide: {
            i18next: appVue.config.globalProperties.$i18next,
            // $date
            date: (d: string | Date | null) => {
                if (!d) {
                    return '—'
                }
                return new Date(d).toLocaleDateString(lng)
            },
        },
    }
})
