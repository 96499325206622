import type { FetchOpts } from '~/utils/fetcher'

export function useFetchCall<Result, Body>(
    endpoint = '/',
    body: Body,
    opts: FetchOpts = {},
): Promise<Result> {
    return new Promise<Result>((resolve, reject) => {
        const { exec } = fetcher({
            background: true,
            ...opts,
            makeUrl: () => endpoint,
            makeBody: () => body ?? {},
            onResponse: json => resolve(json),
            onError: err => reject(err),
        })
        exec()
    })
}
