<template>
    <Transition name="r-modal-tr">
        <div v-show="tagName" class="r-modal" :class="compClassName">
            <div class="r-modal-overlay" @click="localClose" />
            <div class="r-modal-wrap">
                <RModalHeader v-if="modal" :modal="modal" @close="localClose" />
                <div class="r-modal-content small-scroll" :style="{ padding: modal?.contentPadding }">
                    <component :is="modal?.component" ref="comp" v-bind="modal?.props" />
                </div>
            </div>
        </div>
    </Transition>
</template>

<script setup lang="ts">
import { useModal } from '~/components/UiKit'
import { useBase } from '~/components/UiKit/base/composables/useBase'
import RModalHeader from '~/components/UiKit/modal/RModalHeader.vue'

/**
 * Use
 */
const { modal, close } = useModal()
const { toKebabCase } = useBase()

const route = useRoute()

/**
 * Data
 */
const currentSize = ref('')
const comp = ref<any>(null)

/**
 * Computed
 */
const tagName = computed(() => {
    if (!modal.value?.component) {
        return ''
    }
    let name = modal.value.component.__name
    if (!name && modal.value.component.__file) {
        let tmp = modal.value.component.__file.split('/')
        name = (tmp[tmp.length - 1].split('.')[0])
    }
    if (!name) {
        throw new Error('Cannot find name of component')
    }
    return name
})
const compClassName = computed(() => {
    const list = [`r-modal-${currentSize.value}-size`]
    if (tagName?.value) {
        list.push('open', `r-modal-${toKebabCase(tagName.value)}`)
    }
    return list.join(' ')
})

/**
 * Watch
 */
watch(() => modal.value?.size, value => {
    if (!value || !modal.value?.component) {
        return
    }
    currentSize.value = value
})
watch(() => route.path, localClose)

/**
 * Hooks
 */
onMounted(() => {
    document.addEventListener('keydown', onKeydown)
})
onUnmounted(() => {
    document.removeEventListener('keydown', onKeydown)
})

/**
 * Methods
 */
function onKeydown(e: KeyboardEvent) {
    if (!modal.value) {
        return
    }
    if (e.key !== 'Escape') {
        return
    }
    localClose()
}

function localClose() {
    if (modal.value?.waiting || !modal.value?.component) {
        return
    }
    // данные из defineExpose
    const defineExposeData = { ...comp.value }
    close(Object.keys(defineExposeData).length ? defineExposeData : undefined)
}
</script>

<style>
.r-modal-tr-enter-active,
.r-modal-tr-leave-active {
    transition: opacity 0.4s ease;

    .r-modal-wrap {
        bottom: 0;
        transition: bottom 0.2s ease;
    }
}

.r-modal-tr-enter-from,
.r-modal-tr-leave-to {
    opacity: 0;

    .r-modal-wrap {
        bottom: -50px;
    }
}

.r-modal {
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    box-sizing: border-box;
    background-color: #64646480;
    display: flex;
}

.r-modal-wrap {
    position: relative;
    margin: auto;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
}

.r-modal-small-size .r-modal-wrap {
    min-width: 260px;
    width: 260px;
    max-width: 260px;
    min-height: 120px;
    height: fit-content;
    max-height: 90%;
}

.r-modal-middle-size .r-modal-wrap {
    min-width: 520px;
    width: 520px;
    max-width: 520px;
    min-height: 240px;
    height: fit-content;
    max-height: 90%;
}

.r-modal-large-size .r-modal-wrap {
    min-width: 1040px;
    width: 1040px;
    max-width: 1040px;
    min-height: 480px;
    height: fit-content;
    max-height: 90%;
}

.r-modal-vertical-size .r-modal-wrap {
    min-width: 1040px;
    width: 90%;
    max-width: 1200px;
    min-height: 90%;
    height: 90%;
    max-height: 90%;
}

.r-modal-full-size .r-modal-wrap {
    min-width: 1040px;
    width: 90%;
    max-width: 90%;
    min-height: 90%;
    height: 90%;
    max-height: 90%;
}

.r-modal-stepper-size .r-modal-wrap {
    width: 800px;
    height: 600px;
}

.r-modal-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
}

.r-modal-header {
    display: flex;
    align-items: center;
    height: 50px;
    min-height: 50px;
    background: #dfe8ef;

    .r-modal-header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--app-color);
        border-radius: 0 0 6px 0;
        width: 50px;
        height: 100%;
    }

    .r-modal-header-title {
        padding: 0 2em;
        font-size: 16px;
        box-sizing: border-box;
    }

    .r-modal-header-chunk-title {
        margin-right: 1em;
    }

    .r-modal-close-key {
        margin-left: auto;
        right: 1em;
    }

    &.r-modal-header-no-title {
        position: absolute;
        z-index: 10;
        right: 0;
        background: unset;
    }
}

.r-modal-content {
    width: 100%;
    padding: 2em;
    /*min-width: 320px;*/
    box-sizing: border-box;
    overflow: auto;
    position: relative;
    flex-grow: 1;
}

.r-modal-close {
    position: absolute;
    top: 0;
    right: 8px;
    font-size: 40px;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

.r-modal-arr-close {
    position: absolute;
    top: 1px;
    left: 8px;
    font-size: 45px;
    width: 40px;
    line-height: 40px;
    height: 40px;
    text-align: center;
    cursor: pointer;
}

.r-modal-opened {
    overflow: hidden;

    #container,
    #header {
        filter: blur(3px);
    }
}
</style>
