import type { IModalOpts } from '~/components/UiKit'

const modalSingleton = ref<IModalOpts>()
let resolve: any

export function useModal() {
    function open(opts: IModalOpts) {
        const defaultProps = { component: null, props: undefined, size: 'small', onClose: undefined }
        const props: any = { ...defaultProps, ...opts }
        if (props.component) {
            props.component = markRaw(props.component)
        }

        modalSingleton.value = props

        const promise: Promise<any> = new Promise(res => {
            resolve = res
        })

        return promise
    }

    function close(data?: any) {
        if (!modalSingleton.value) {
            return
        }

        if (modalSingleton.value.waiting) {
            return
        }
        if (modalSingleton.value.onClose) {
            modalSingleton.value.onClose(modalSingleton.value)
        }
        modalSingleton.value = undefined
        resolve(data)
        return false
    }

    function setTitle(title: string | string[]) {
        if (!modalSingleton.value) {
            return
        }
        modalSingleton.value.title = title
    }

    function setWaiting(waiting: boolean) {
        if (!modalSingleton.value) {
            return
        }
        modalSingleton.value.waiting = waiting
    }

    return { modal: modalSingleton, open, close, setTitle, setWaiting }
}
