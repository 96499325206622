<template>
    <div>
        <RIcon
            v-if="iconName"
            class="r-dialog-type-icon"
            width="40"
            :name="iconName"
        />
        <div class="r-dialog-item-text-wrap">
            <div v-if="dialog.title" class="r-dialog-header">{{ dialog.title }}</div>
            <div class="small-scroll r-dialog-content">
                <template v-if="dialog.content">
                    <template v-if="Array.isArray(dialog.content)">
                        <p v-for="(d, i) in dialog.content" :key="i" v-html="d" />
                    </template>
                    <p v-else v-html="dialog.content" />
                </template>
                <template v-if="dialog.component">
                    <component
                        :is="dialog.component"
                        v-bind="dialog.props ? dialog.props : {}"
                        :dialog-id="dialog.id"
                        :close-waiting="waiting"
                    />
                </template>
                <RIcon
                    v-if="dialog.help"
                    width="20"
                    color="white"
                    class="r-dialog-help"
                    name="information"
                    :title="dialog.help"
                />
            </div>
        </div>
        <RBtn
            v-if="dialog.closeKeyDelay !== 0"
            mode="positive"
            icon="cancel"
            :icon-color="waiting ? 'var(--text-light-grey)' : '#000000'"
            class="r-dialog-close"
            :wait="waiting"
            @click="close({ id: dialog.id as string | number, ref: 'closeBtn' })"
        />
        <div v-if="autocloseTimer" class="r-dialog-autoclose-timer">
            {{ $t('@autocloseTimer', { count: autocloseTimer }) }}...
        </div>
    </div>
</template>

<script setup lang="ts">
import type { IDialogOpts } from '~/components/UiKit'

/**
 * Props
 */
const props = defineProps<{
    dialog: IDialogOpts
    close: (...args: [{ id: string | number, ref?: string }]) => void
}>()

/**
 * Data
 */
const autocloseTimer = ref(props.dialog.duration ? Math.round(props.dialog.duration / 1000) : 0)

if (autocloseTimer.value) {
    setInterval(() => {
        autocloseTimer.value--
    }, 1000)
}

/**
 * Computed
 */
const iconName = computed(() => {
    if (props.dialog.icon) {
        return props.dialog.icon
    }
    switch (props.dialog.type) {
        case 'error':
        case 'warning':
            return 'warning'
        case 'success':
            return 'ok'
        case 'info':
            return 'information'
    }
    return ''
})
const waiting = computed(() => {
    return props.dialog.closeKeyDelay !== undefined
})
</script>
