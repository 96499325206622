import * as Sentry from '@sentry/vue'
import { defineNuxtPlugin, useRuntimeConfig } from 'nuxt/app'
import { useAppStore } from '~/stores/app'
import { useUserStore } from '~/stores/user'

export default defineNuxtPlugin({
    name: 'sentry',
    parallel: true,
    async setup(nuxtApp) {
        const vueApp = nuxtApp.vueApp
        const config = useRuntimeConfig()

        Sentry.init({
            app: vueApp,
            // todo provide at build time
            dsn: 'https://725388d840c8223ed9b6048e2b277b6d@sentry.referus.ru/2',
            enabled: config.public.IS_DEV !== 'Y',
            environment: new URL(location.href).hostname,
            // logErrors: false,
            autoSessionTracking: false,
            normalizeDepth: 5,
            maxValueLength: 160,
            ignoreErrors: ['AbortError'],
            beforeSend: (ev, hint) => {
                ev.release = useAppStore().frontVersion
                ev.user = { id: useUserStore().sid }

                if (config.public.IS_DEV === 'Y') {
                    // eslint-disable-next-line no-console
                    console.debug('Error->Sentry', ev)
                    // don't send
                    return null
                }
                return ev
            },
        })

        return {
            provide: {
                sentry: Sentry,
            },
        }
    },
})
