export function useBase() {
    function randKey() {
        let id = Date.now()
            .toString()
            .split('')
            .reverse()
            .map(() => rand(0, 9))
            .join('')
        return `r-${id}`
    }
    function rand(min: number, max: number) {
        return Math.floor(Math.random() * (max - min + 1)) + min
    }

    function toKebabCase(str: string) {
        return str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()
    }
    return { randKey, toKebabCase }
}
