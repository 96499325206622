import type { IDialogOpts, IPopupOpts, IToastOpts } from '~/components/UiKit'

const cleaner = new HtmlSanitizer({
    tags: ['a', 'b', 'i', 'div', 'span', 'br', 'pre', 'small'],
    attr: ['href', 'target', 'class'],
    protocols: ['http', 'https'],
})

interface PromiseStackItem {
    promise: Promise<any>
    resolve: (value: unknown) => void
}

export function usePopup(name: string) {
    const items = ref<(IDialogOpts | IPopupOpts)[]>([])

    const promiseStack: PromiseStackItem[] = []

    function open(opts: IPopupOpts | IToastOpts | IDialogOpts | string) {
        const defaultProps: IPopupOpts | IToastOpts | IDialogOpts = {
            id: Date.now(),
            duration: name === 'toast' ? 3000 : 0,
            type: undefined,
            title: '',
            content: '',
            component: '',
            props: null,
            closeKeyDelay: undefined,
            color: undefined,
            icon: undefined,
            help: '',
        }
        const props: any = {
            ...defaultProps,
            ...(typeof opts === 'string' ? { content: opts } : opts),
        }

        if (props.content) {
            if (Array.isArray(props.content)) {
                props.content = props.content.map((i: string) => cleaner.clean(i))
            } else {
                props.content = cleaner.clean(props.content)
            }
        }

        if (props.component) {
            props.component = markRaw(props.component)
        }

        if (props.closeKeyDelay === 0 && !props.duration) {
            throw new Error('unclosable window')
        }

        const index = props.id ? items.value.findIndex(d => d.id === props.id) : -1
        let itemPromise: PromiseStackItem

        // перерисовать с таким же id
        if (index > -1) {
            items.value.splice(index, 1, props)
            itemPromise = promiseStack[index]
        } else {
            let resolve: any
            const promise: Promise<any> = new Promise(res => {
                resolve = res
            })
            itemPromise = { promise, resolve }
            items.value.push(props)
            promiseStack.push(itemPromise)
        }

        if (props.duration) {
            setTimeout(() => {
                props.id && close({ id: props.id, ref: 'duration' })
            }, props.duration)
        }

        if (props.closeKeyDelay) {
            setTimeout(() => {
                const item = items.value.find(i => i.id === props.id)
                if (!item || !('closeKeyDelay' in item)) {
                    return
                }
                item.closeKeyDelay = undefined
            }, props.closeKeyDelay)
        }

        return itemPromise.promise
    }

    function close({ id, ref = 'emit', data }: { id: string | number, ref?: string, data?: any }) {
        if (!id) {
            throw new Error('empty id')
        }
        const index = items.value.findIndex(d => d.id === id)
        const item = items.value[index]

        if (!item) {
            return
        }

        if ((item as any).closeKeyDelay !== undefined) {
            return
        }
        items.value.splice(index, 1)

        let tmp = { ref, index, ...item }
        if (item.onClose) {
            item.onClose({ id, ref, data })
        }

        promiseStack[index].resolve(data)
        promiseStack.splice(index, 1)

        return tmp
    }

    function getClasses(index: number) {
        const item = items.value[index]
        const classList = [`r-${name}`]
        if ('color' in item && item.color) {
            classList.push(item.color)
        } else if (item.type) {
            classList.push(item.type)
        } else {
            classList.push('neutral')
        }
        if (!item.title) {
            classList.push(`r-${name}-no-title`)
        }
        return classList.join(' ')
    }

    function onKeydown(e: KeyboardEvent) {
        if (!items.value.length) {
            return
        }
        if (e.key !== 'Escape') {
            return
        }
        const id = items.value[items.value.length - 1].id

        if (!id) {
            return
        }
        close({ id, ref: 'escape' })
    }

    return { items, open, close, getClasses, onKeydown }
}
