export function domParents(el: HTMLElement) {
    const els: HTMLElement[] = []
    let tmp: HTMLElement = el
    while (tmp && tmp.tagName !== 'BODY') {
        els.unshift(tmp)
        tmp = tmp.parentNode as HTMLElement
    }
    return els
}

export function firstToUpper(str: string) {
    return `${str[0].toUpperCase()}${str.slice(1)}`
}

export function objectDiffs(obj1: Record<string, any>, obj2: Record<string, any>) {
    const diffs: any[] = []
    pushDiffs(obj1, obj2)
    pushDiffs(obj2, obj1)

    function pushDiffs(a: Record<string, any>, b: Record<string, any>) {
        Object.keys(a).forEach(key => {
            const val1 = a[key]
            const val2 = b[key]
            if (
                val1 === val2
                || (!val1 && !val1 === !val2 && !((val1 === null && +val2 === 0) || (val2 === null && +val1 === 0)))
            ) {
                return
            }
            !diffs.some(d => d.key === key) && diffs.push({ key, val1, val2 })
        })
    }

    return diffs
}

export function getFlatTree<T extends { children: T[] }>(tree: T[]): T[] {
    const result: T[] = []
    const recursive = (node: T) => {
        result.push(node)
        node.children.forEach(child => recursive(child))
    }
    tree.forEach(node => recursive(node))
    return result
}
