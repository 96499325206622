import type { SystemLanguage } from 'back/src/services/Language'

export const systemLanguages: SystemLanguage[] = []

const systemLanguageMapCode: Map<string, SystemLanguage> = new Map()

export function systemLanguagesLoad(items: SystemLanguage[]) {
    systemLanguages.splice(0, systemLanguages.length)
    systemLanguageMapCode.clear()
    items.forEach(i => {
        systemLanguages.push(i)
        systemLanguageMapCode.set(i.code, i)
    })
}

function fakeLanguage(code: string): SystemLanguage {
    return {
        id: code,
        code,
        systemStatus: 'hold',
        dataUsage: false,
        uiUsage: true,
        name: code,
        nameEn: code,
        localisationRule: 'en',
    }
}

export function systemLanguageByCode(code: string) {
    return systemLanguageMapCode.get(code) || fakeLanguage(code)
}
