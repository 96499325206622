import * as Sentry from '@sentry/vue'
import { t } from 'i18next'
import DuplicationErrorView from '~/components/DuplicationErrorView.vue'
import { useDialog } from '~/components/UiKit'
import { DuplicationError } from '~/utils/fetcher'

export default defineNuxtPlugin(nuxtApp => {
    nuxtApp.hook('app:manifest:update', p => {
        // eslint-disable-next-line no-console
        console.log('app:manifest:update', p)
    })

    nuxtApp.vueApp.config.errorHandler = (error, instance, info) => {
        handler(error)
    }

    nuxtApp.hook('vue:error', (error, instance, info) => {
        handler(error)
    })

    function handler(err: any) {
        if (err.handled) {
            return
        }
        err.handled = true

        Sentry.captureException(err)

        if (err instanceof DuplicationError) {
            useDialog().open({
                title: t('@rejected'),
                component: DuplicationErrorView,
                props: { err },
                duration: 120_000,
                type: 'error',
            })
            return
        }

        const errorText = err.message
        if (errorText) {
            useAppError().show(errorText, t('@error'))
            console.error(err)
        }
    }
})
